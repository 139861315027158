import TicketsConfigs from '../types/TicketsConfigs';

export const ticketsConfigs: TicketsConfigs = [
  // Единые с лимитом поездок
  {
    name: 'Единый на 1 поездку',
    price: 62,
    dayLimit: 5,
    tripLimit: 1,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'allInOne_trips_1',
    groupId: 'allInOne_trips',
    isSelectedByDefault: true,
    isIgnored: false,
    color: '168, 71, 67',
  },

  {
    name: 'Единый на 2 поездки',
    price: 124,
    dayLimit: 5,
    tripLimit: 2,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'allInOne_trips_2',
    groupId: 'allInOne_trips',
    isSelectedByDefault: true,
    isIgnored: false,
    color: '181, 76, 72',
  },

  {
    name: 'Единый на 60 поездок',
    price: 2730,
    dayLimit: 45,
    tripLimit: 60,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'allInOne_trips_60',
    groupId: 'allInOne_trips',
    isSelectedByDefault: true,
    isIgnored: false,
    dependencies: [
      'compound__tat_days_30__allInOne_trips_60',
      'compound__tat_days_90__allInOne_trips_60',
      'compound__tat_days_365__allInOne_trips_60',
    ],
    color: '193, 81, 77',
  },

  // Единые без лимита
  {
    name: 'Единый на 1 сутки',
    price: 285,
    dayLimit: 1,
    tripLimit: Infinity,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'allInOne_days_1',
    groupId: 'allInOne_days',
    isSelectedByDefault: true,
    isIgnored: false,
    color: '205, 86, 82',
  },

  {
    name: 'Единый на 3 суток',
    price: 540,
    dayLimit: 3,
    tripLimit: Infinity,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'allInOne_days_3',
    groupId: 'allInOne_days',
    isSelectedByDefault: true,
    isIgnored: false,
    color: '217, 91, 87',
  },

  {
    name: 'Единый на 30 дней',
    price: 2540,
    dayLimit: 30,
    tripLimit: Infinity,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'allInOne_days_30',
    groupId: 'allInOne_days',
    isSelectedByDefault: true,
    isIgnored: false,
    color: '229, 96, 92',
  },

  {
    name: 'Единый на 90 дней',
    price: 6150,
    dayLimit: 90,
    tripLimit: Infinity,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'allInOne_days_90',
    groupId: 'allInOne_days',
    isSelectedByDefault: true,
    isIgnored: false,
    color: '217, 101, 97',
  },

  {
    name: 'Единый на 365 дней',
    price: 19500,
    dayLimit: 365,
    tripLimit: Infinity,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'allInOne_days_365',
    groupId: 'allInOne_days',
    isSelectedByDefault: true,
    isIgnored: false,
    color: '229, 106, 112',
  },

  // Карта Тройка
  {
    name: 'Электронный кошелек',
    price: 50,
    dayLimit: Infinity,
    tripLimit: 1,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'troika',
    groupId: 'troika',
    isSelectedByDefault: true,
    isIgnored: false,
    dependencies: [
      'compound__troika__facepay',
      'compound__tat_days_30__troika',
      'compound__tat_days_90__troika',
      'compound__tat_days_365__troika',
    ],
    color: '16, 166, 200',
  },

  {
    name: 'Пересадка (90 минут)',
    price: 75,
    dayLimit: Infinity,
    tripLimit: 1,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'troika_90min',
    groupId: 'troika',
    isSelectedByDefault: false,
    isIgnored: true,
  },

  // Банковские карты
  {
    name: 'Банковская карта',
    price: 56,
    dayLimit: Infinity,
    tripLimit: 1,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'bankcard',
    groupId: 'other',
    isSelectedByDefault: true,
    isIgnored: false,
    color: '28, 28, 28',
  },
  {
    name: 'Facepay',
    price: 46,
    dayLimit: Infinity,
    tripLimit: 1,
    isValidForMetro: true,
    isValidForTat: false,
    id: 'facepay',
    groupId: 'other',
    isSelectedByDefault: true,
    isIgnored: false,
    dependencies: [
      'compound__troika__facepay',
      'compound__tat_days_30__facepay',
      'compound__tat_days_90__facepay',
      'compound__tat_days_365__facepay',
    ],
    color: '245, 163, 0',
  },

  // {
  //   name: 'Карта Мир (до 31.12.2022)',
  //   price: 41,
  //   dayLimit: Infinity,
  //   tripLimit: 1,
  //   isValidForMetro: true,
  //   isValidForTat: true,
  //   id: 'bankcard_mir-promo',
  //   groupId: 'other',
  //   isSelectedByDefault: true,
  //   isIgnored: false,
  //   color: '0, 144, 94',
  // },

  // Билеты ТАТ
  {
    name: 'ТАТ на 30 дней',
    price: 1560,
    dayLimit: 30,
    tripLimit: Infinity,
    isValidForMetro: false,
    isValidForTat: true,
    id: 'tat_days_30',
    groupId: 'tat',
    isSelectedByDefault: true,
    isIgnored: false,
    dependencies: [
      'compound__tat_days_30__troika',
      'compound__tat_days_30__facepay',
      'compound__tat_days_30__allInOne_trips_60',
    ],
    color: '0, 85, 128',
  },

  {
    name: 'ТАТ на 90 дней',
    price: 4140,
    dayLimit: 90,
    tripLimit: Infinity,
    isValidForMetro: false,
    isValidForTat: true,
    id: 'tat_days_90',
    groupId: 'tat',
    isSelectedByDefault: true,
    isIgnored: false,
    dependencies: [
      'compound__tat_days_90__troika',
      'compound__tat_days_90__facepay',
      'compound__tat_days_90__allInOne_trips_60',
    ],
    color: '0, 102, 153',
  },

  {
    name: 'ТАТ на 365 дней',
    price: 14000,
    dayLimit: 365,
    tripLimit: Infinity,
    isValidForMetro: false,
    isValidForTat: true,
    id: 'tat_days_365',
    groupId: 'tat',
    isSelectedByDefault: true,
    isIgnored: false,
    dependencies: [
      'compound__tat_days_365__troika',
      'compound__tat_days_365__facepay',
      'compound__tat_days_365__allInOne_trips_60',
    ],
    color: '0, 125, 189',
  },

  // Сочетания билетов
  // Нужно добавить useForMetro, useForTat

  {
    name: 'Facepay + Тройка',
    price: null,
    dayLimit: null,
    tripLimit: null,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'compound__troika__facepay',
    groupId: 'compound',
    isSelectedByDefault: true,
    isIgnored: false,
    useForMetro: 'facepay',
    useForTat: 'troika',
    color: '245, 163, 0',
  },

  {
    name: 'ТАТ на 30 дней + Тройка',
    price: null,
    dayLimit: null,
    tripLimit: null,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'compound__tat_days_30__troika',
    groupId: 'compound',
    isSelectedByDefault: true,
    isIgnored: false,
    useForMetro: 'troika',
    useForTat: 'tat_days_30',
    color: '0, 85, 128',
  },

  {
    name: 'ТАТ на 30 дней + Facepay',
    price: null,
    dayLimit: null,
    tripLimit: null,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'compound__tat_days_30__facepay',
    groupId: 'compound',
    isSelectedByDefault: true,
    isIgnored: false,
    useForMetro: 'facepay',
    useForTat: 'tat_days_30',
    color: '0, 85, 128',
  },

  {
    name: 'ТАТ на 90 дней + Тройка',
    price: null,
    dayLimit: null,
    tripLimit: null,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'compound__tat_days_90__troika',
    groupId: 'compound',
    isSelectedByDefault: true,
    isIgnored: false,
    useForMetro: 'troika',
    useForTat: 'tat_days_90',
    color: '0, 102, 153',
  },

  {
    name: 'ТАТ на 90 дней + Facepay',
    price: null,
    dayLimit: null,
    tripLimit: null,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'compound__tat_days_90__facepay',
    groupId: 'compound',
    isSelectedByDefault: true,
    isIgnored: false,
    useForMetro: 'facepay',
    useForTat: 'tat_days_90',
    color: '0, 102, 153',
  },

  {
    name: 'ТАТ на 365 дней + Тройка',
    price: null,
    dayLimit: null,
    tripLimit: null,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'compound__tat_days_365__troika',
    groupId: 'compound',
    isSelectedByDefault: true,
    isIgnored: false,
    useForMetro: 'troika',
    useForTat: 'tat_days_365',
    color: '0, 125, 189',
  },

  {
    name: 'ТАТ на 365 дней + Facepay',
    price: null,
    dayLimit: null,
    tripLimit: null,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'compound__tat_days_365__facepay',
    groupId: 'compound',
    isSelectedByDefault: true,
    isIgnored: false,
    useForMetro: 'facepay',
    useForTat: 'tat_days_365',
    color: '0, 125, 189',
  },

  {
    name: 'ТАТ на 30 дней + Единый на 60 поездок',
    price: null,
    dayLimit: null,
    tripLimit: null,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'compound__tat_days_30__allInOne_trips_60',
    groupId: 'compound',
    isSelectedByDefault: true,
    isIgnored: false,
    useForMetro: 'allInOne_trips_60',
    useForTat: 'tat_days_30',
    color: '99, 78, 126',
  },

  {
    name: 'ТАТ на 90 дней + Единый на 60 поездок',
    price: null,
    dayLimit: null,
    tripLimit: null,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'compound__tat_days_90__allInOne_trips_60',
    groupId: 'compound',
    isSelectedByDefault: true,
    isIgnored: false,
    useForMetro: 'allInOne_trips_60',
    useForTat: 'tat_days_90',
    color: '109, 85, 139',
  },

  {
    name: 'ТАТ на 365 дней + Единый на 60 поездок',
    price: null,
    dayLimit: null,
    tripLimit: null,
    isValidForMetro: true,
    isValidForTat: true,
    id: 'compound__tat_days_365__allInOne_trips_60',
    groupId: 'compound',
    isSelectedByDefault: true,
    isIgnored: false,
    useForMetro: 'allInOne_trips_60',
    useForTat: 'tat_days_365',
    color: '119, 93, 152',
  },
];

// Параметры групп билетов
export const ticketGroupsConfigs = [
  {
    id: 'troika',
    title: 'Тройка',
  },
  {
    id: 'allInOne_trips',
    title: 'Единый на поездки',
  },
  {
    id: 'allInOne_days',
    title: 'Единый на дни',
  },
  {
    id: 'tat',
    title: 'Наземный транспорт (ТАТ)',
  },
  {
    id: 'other',
    title: 'Другие билеты',
  },
  {
    id: 'compound',
    title: 'Сочетания билетов',
  },
];
